import React, { useEffect, useState } from 'react';
import { ToastKind } from 'design-system/components';
import useFetcher from 'services/api/useFetcher';
import { useToastContext } from 'context/ToastContext';

const defaultErrorState = {
  requestedName: '',
};

interface IngredientRequestFormData {
  requestedName: string;
  notes: string;
}

interface UseIngredientRequestFormProps {
  initialName?: string;
  onSubmit?: (data: IngredientRequestFormData) => void;
  ingredientId: number;
}

export const useIngredientRequestForm = ({
  initialName = '',
  onSubmit,
  ingredientId,
}: UseIngredientRequestFormProps) => {
  const [formData, setFormData] = useState<IngredientRequestFormData>({
    requestedName: initialName,
    notes: '',
  });
  const [errors, setErrors] = useState<{ requestedName: string }>(
    defaultErrorState
  );
  const { toastData, setToastData } = useToastContext();

  const fetcher = useFetcher();

  useEffect(() => {
    setFormData((prev) => ({ ...prev, requestedName: initialName }));
  }, [initialName]);

  const handleInputChange =
    (field: keyof IngredientRequestFormData) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({ ...prev, [field]: event.target.value }));
    };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formData.requestedName.trim()) {
      setErrors({ requestedName: 'Ingredient name is required.' });
      return;
    }

    try {
      await fetcher(`/api/v4/ingredient_requests`, {
        method: 'POST',
        body: {
          'product_design_formulation_chemical_ingredient_id': ingredientId,
          'requested_name': formData.requestedName,
          'notes': formData.notes,
        },
      });

      setErrors(defaultErrorState);
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message: 'Ingredient request submitted successfully!',
        },
      ]);
      onSubmit?.(formData);
    } catch (error: any) {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Error,
          title: 'Error',
          message: error.message || 'Failed to submit ingredient request',
        },
      ]);
    }
  };

  return {
    formData,
    errors,
    handleInputChange,
    handleSubmit,
    setFormData,
    ingredientId,
    toastData,
  };
};

import { useCallback, useEffect, useState } from 'react';
import { object, func, bool } from 'prop-types';

import {
  SearchOnKeystroke,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { ToastProvider } from 'context/ToastContext';

import fetcher from 'services/api/fetcher';
import { RequirementActionContentType } from 'views/Brands/RetailerBrandRequirementSummary/constants';
import { IngredientRequestDialogTrigger } from 'components/IngredientRequest';
import { useApp } from 'context/AppContext';

import styles from '../../cta-modals.module.scss';

function ResolveName({
  condition,
  onChange,
  triggerValidation,
  onValidate,
  canAttest,
  setHasChanged,
}) {
  const { user } = useApp();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [error, setError] = useState(null);

  const handleSearch = async (searchTerm) => {
    const url = `/api/v4/autocomplete/chemical_compounds?term=${searchTerm}`;
    const { results } = await fetcher(url);
    setSearchResults(results);
  };

  const handleChange = (value) => {
    setSelectedIngredient(value);
    setHasChanged();

    return onChange(
      condition?.conditionId,
      RequirementActionContentType.ResolveIngredient,
      {
        value: value.id,
        noteValue: null,
        conditionType: RequirementActionContentType.ResolveIngredient,
      },
      condition?.policyRequirementId
    );
  };

  const validate = useCallback(() => {
    if (!selectedIngredient) {
      setError('You must select an ingredient.');
      return false;
    }
    setError(null);
    return true;
  }, [selectedIngredient]);

  useEffect(() => {
    if (triggerValidation) {
      const isValid = validate();
      onValidate(condition?.policyRequirementId, isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation]);

  return (
    <ToastProvider>
      <div className={styles['modal-content']}>
        <Text
          kind={TextKind.TextSMBold}
          element={TextElement.P}
          className={styles['header-text']}
        >
          Ingredient name
        </Text>
        <SearchOnKeystroke
          placeholder="Find ingredient..."
          onSearch={handleSearch}
          onChange={(value) => handleChange(value)}
          value={selectedIngredient}
          options={searchResults}
          disabled={!canAttest}
        />
        {error && (
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Orange600}
          >
            {error}
          </Text>
        )}
        {user?.hasFF('tmp_request_ingredients') && (
          <IngredientRequestDialogTrigger
            unresolvedName={condition?.name}
            ingredientId={condition?.ingredient_id}
          />
        )}
      </div>
    </ToastProvider>
  );
}

ResolveName.propTypes = {
  condition: object.isRequired,
  onChange: func.isRequired,
  canAttest: bool,
};

export default ResolveName;

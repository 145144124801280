import { ToastData, ToastV2Menu } from 'design-system/components';
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';

/**
 * ToastContext provides a centralized way to manage toast notifications across the application. Eventually, we can wrap the app in this context to provide a global toast provider.
 *
 * Advantages:
 * - Elevates toast state management to a higher level, making it accessible throughout the component tree
 * - Allows any child component to trigger toasts without prop drilling
 * - Maintains a single source of truth for toast state
 * - Simplifies toast management by providing a consistent interface (useToastContext hook)
 * - Enables showing toasts from deeply nested components without passing callbacks through multiple levels
 */

interface ToastContextType {
  toastData: ToastData[];
  setToastData: React.Dispatch<React.SetStateAction<ToastData[]>>;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toastData, setToastData] = useState<ToastData[]>([]);

  const closeToast = () => {
    setIsOpen(false);
  };

  const handleSetToastData = useCallback(
    (data: ToastData[] | ((prev: ToastData[]) => ToastData[])) => {
      setToastData(data);
      setIsOpen(true);
    },
    [setToastData, setIsOpen]
  );

  const value = useMemo(
    () => ({
      toastData,
      setToastData: handleSetToastData,
    }),
    [toastData, handleSetToastData]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastV2Menu open={isOpen} toastData={toastData} onClose={closeToast} />
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }
  return context;
};
